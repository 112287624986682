
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumbs/breadcrumb";

export default defineComponent({
  name: "pricing",
  components: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("General", "Pricing 2");
    });
  }
});
